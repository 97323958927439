.NavigationItems {
  align-items: center;
  display: flex;
  gap: 3rem;
  height: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}
.logout-btn{
  background-color: transparent;
  border: none;
  outline: none;
  color: white;
  font-size: 18px;
}
@media (max-width: 49rem) {
  .NavigationItems {
    border-top: 1px solid #ccc;
    margin-top: 3rem;
  }
}

.Logo {
    padding: 0.75rem;
    height: 15%;
    box-sizing: border-box;
    border-radius: 5px;
    width: 50%;
    /* margin-right:500px;  */
}

.Logo img {
    height: 100%;
}
@media (max-width: 990px) {
    .Logo{
        padding: 1rem;
    }
}
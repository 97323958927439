.navItem {
  width: 100%;
  border-right: 0.1px solid white;
  border-left: 0.1px solid white;
  background-color: #5c325f;
  padding: 0.625rem 1.5rem;

}
.test {
  color: red;
}

.navItem a {
  color: white;
  text-decoration: none;
  width: 100%;

}

.navItem:hover {
  background-color: #ffcce9;
  width: 100%;
}

.category {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 70vw;
}
.category__content {
  width: 80%;
}
.category__sideNews {
  width: 20%;
  margin-left: 1.25rem;
}
.category__items {
  display: flex;
}
.category__items-img {
  width: 40%;
  height: 200px;
}
.category__items-title {
  width: 60%;
  padding-left: 1.875rem;
}
.category__items-title-text {
  
  overflow: hidden;
  /*ANKHAARKAH! ZASAKH textnii ehnii 3 mur ... tsegtei gargadag boloh*/
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.category__items-title-text iframe{
  display: none;
}
.category__pagination {
  display: flex;
  justify-content: space-between;
}

.pagination__btn{
  color: black;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 0.625rem 1.25rem;
  transition: background-color 0.3s;
  cursor: pointer;
}
.pagination__btn:hover{
  background-color: #ddd;

}

@media (max-width: 61.875em) {
  .category__content{
    width: 95vw;
  }
  .category__pagination a {
    padding: 1.25rem;
    font-weight: bold;
  }
  .category__sideNews {
    display: none;
  }
  .category__items-title-text .raw-html-embed{
    height: 100%;
    display: flex;
  }
  .category__items-title-text .raw-html-embed iframe{
    height: 220px;
    }
}
@media (max-width: 768px) {
  .category__items-title-text .raw-html-embed{
    display: flex;
  }
  .category__items-title-text .raw-html-embed iframe{
    height: 150px;
  }
  .category__items-title a h4{
    font-size: 1.5rem;
  }
  .category__items{
    width: 90vw;
  }
}

/* @media (max-width: 768px) {
  .category__content{
    width: 80%;
  }
}

@media (max-width: 600px) {
  .category__content{
    width: 75%;
    padding: 0 16rem;

  }
}
@media (max-width: 480px) {
  .category__content{
    width: 60%;
    padding: 0 3.125rem;

  }
}
@media (max-width: 350px) {
  .category__content{
    width: 40%;
    padding: 0 10px;
  }
} */
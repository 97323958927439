.DrawerToggle {
  width: 40px;
  height: 100%;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding: 0.625rem 0;
  box-sizing: border-box;
  margin-left: 3rem;
  cursor: pointer;
}

.DrawerToggle div {
  width: 90%;
  height: 10%;
  background-color: white;
}

@media (min-width: 61.875rem) {
  .DrawerToggle {
    display: none;
  }
}

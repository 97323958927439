.specialNews {
  position: relative;
  height: 100%;
}
.specialNews__figure {
  width: 100%;
  height: 100%;
}
.specialNews__img {
  width: 100%;
  height: 100%;
}
.specialNews__figcaption {
  position: absolute;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: 80px;
  padding: 0 0.625rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: bold;
}
.otherNews__title {
  color: '#5C325F';
}
.otherNews__title-line-1 {
  display: flex;
}
.otherNews__title-line-1_1 {
  border-bottom: 3px solid #5c325f;
  width: 20%;
}
.otherNews__title-line-1_2 {
  border-bottom: 3px solid lightgray;
  width: 80%;
}
.otherNews__title-line-2 {
  display: block;
  border-bottom: 3px solid #5c325f;
  width: 100%;
}
.otherNews__items-first {
  border: 1px solid gray;
  margin-top: 0.625rem;
}
.otherNews__items-first-body {
  position: relative;
  padding: 0.3125rem;
}
.otherNews__items-first-body-title {
  height: 75px;
}
.otherNews__items-first-body-date {
  position: absolute;
  bottom: 0;
  right: 10px;
}
.otherNews__items-item {
  border: 1px solid gray;
  border-top: none;
  height: 90px;
  position: relative;
  padding: 0.3125rem;
}
.otherNews__items-item-date {
  position: absolute;
  bottom: 0;
  right: 10px;
}
.box1__figure {
  position: relative;
  width: 100%;
  height: 100%;
}
.box1__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* index */
.container {
  position: relative;
  font-family: Roboto;
}
.category {
  width: 100%;
  height: 200px;
}

.slideNews .textBlock {
  list-style: none;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8); 
  color: #f1f1f1;
  width: 100%;
  padding: 0.25rem;
  height: 55px;
  font-size: 1.0625rem;
}
.slideNews1 .textBlock {
  list-style: none;
  position: absolute;
  bottom: 0;
  padding: 0;
  background: rgba(0, 0, 0, 0.8); 
  color: #f1f1f1;
  width: 100%;
  padding-bottom: 3%;
  font-size: 1rem;
  padding-left: 0.625rem;
}
.slideNews a img {
  width: 100%;
  height: 360px;
}
.slideNews1 a img {
  width: 100%;
  height: 198px;
  margin-top: 0.3125rem;
  font-size: 0.875rem;
}

.news .side__news-img {
  width: 100%;
  height: 112px;
  padding-bottom: 0.3125rem;
  position: relative;
}
* {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  color: #5c325f;
}
.font {
  position: relative;
  bottom: 0;
  background: rgb(0, 0, 0); /* Fallback color */
  background: rgba(0, 0, 0, 0.5); /* Black background with 0.5 opacity */
  color: #f1f1f1;
  width: 100%;
  padding: 1.25rem;
}
.font1 {
  color: black;
  font-size: 80%;
}
.font2 {
  color: white;
  font-size: 80%;
}
.Font {
  padding: 0 0;
}
table,
td,
th {
  border: 1px solid lightgray;
}
.Cnews {
  height: 65px;
  padding-left: 0.625rem;
}
a:hover {
  color: #5c325f;
  text-decoration: none;
}
.timelink:hover {
  color: black;
}
#col {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}
.bstrap {
  padding: 0;
  margin: 0;
}
.body__news-moreNews__title{
  font-size: 1.5rem;
  /* text-align: center; */
}
@media (min-width: 61.875rem) {
  .banner {
    display: none;
  }
}

@media (max-width: 61.875rem) {
  .news {
    flex-direction: column;
    align-items: center;
  }
  .news .side__news-img {
    width: 100%;
    height: 100%;
  }
  .mobile2 {
    background-color: green;
  }
  .font1 {
    font-family: Roboto;
    color: black;
    font-size: 2.5rem;
  }
  .hide {
    display: none;
  }
  .category {
    width: 100%;
    height: 100%;
  }
  .space {
    margin-top: 5rem;
  }
  .slideNews .textBlock {
    list-style: none;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 98%;
    padding-bottom: 3%;
    margin-left: 1%;
  }
  .slideNews a img {
    width: 100%;
    height: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .slideNews1 .textBlock {
    list-style: none;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); /* Black background with 0.5 opacity */
    color: #f1f1f1;
    width: 96%;
    padding-bottom: 3%;
    margin-left: 2%;
  }
  .slideNews1 a img {
    width: 100%;
    height: 280px;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    position: relative;
  }
  .news img {
    width: 100%;
    height: 100%;
    padding-left: 0.625rem;
    padding-right: 0.625rem;
    position: relative;
  }
  .mobile1 {
    padding: 0;
    margin-right: 1.25rem;
    position: relative;
    width: 100%;
    font-size: 1.875rem;
    margin-bottom: 0.525rem;
  }

  .timelink {
    display: none;
  }
  .body__news-moreNews__box{
    text-align: center;
  }
  .body__news-moreNews__title{
    font-size: 1.5rem;
    /* text-align: center; */
  }
}
/* index */
@media (max-width: 37.5rem) {
  .specialNews__img{
    object-fit: cover;
  }
  .specialNews__figcaption{
    font-size: 1rem;
  }
}

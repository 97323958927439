.NavigationItem {
  box-sizing: border-box;
  display: block;
  margin: 10px 0;
  width: 100%;
}

.NavigationItem a {
  box-sizing: border-box;
  color: white;
  display: block;
  text-decoration: none;
  width: 100%;
  font-size: 1.8rem;

}

.NavigationItem a:hover,
.NavigationItem a:active,
.NavigationItem a.active {
  color: #40a4c8;
}

@media (max-width: 48rem) {

.NavigationItem a{
  color: black;
}
.NavigationItems .logout-btn{
  color: black;
}
  /* .NavigationItem {
    align-items: center;
    display: flex;
    height: 100%;
    margin: 0;
    width: auto;
  }

  .NavigationItem a {
    border-bottom: 4px solid transparent;
    color: white;
    height: 100%;
    padding: 16px 10px;
  }

  .NavigationItem a:hover,
  .NavigationItem a:active,
  .NavigationItem a.active {
    background-color: #e20af1;
    border-bottom: 4px solid #40a4c8;
    color: white;
  } */
}

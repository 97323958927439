.content__title {
  font-size: 2.3rem;
  text-align: center;
}
.content__hr {
  border-radius: 5px;
  height: 5px;
  background-image: linear-gradient(to right, #5c325f, #ffcce9);
}
.content__subtitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.625rem;
}
.content__subtitle_category {
  font-size: 1.8rem;
}
.content__subtitle_icons {
  display: flex;
}
.content__subtitle_icon:not(:last-child) {
  margin-right: 0.1875rem;
}
.content__newsPhoto {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content__figure {
  width: 80%;
  height: 80%;
}
.content__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.content__news {
  font-size: 1.125rem;
  text-align: justify;
  margin-top: 0.9375rem;
}
.ck-content p img{
  width: 100%;
}
.ck-content .raw-html-embed{
  display: flex;
  justify-content: center;
  margin: 1.75rem 0;
}

@media (max-width: 61.875rem) {
  .content__news {
    font-size: 1.875rem;
  }
}

.Toolbar {
  visibility: hidden;
  position: relative;
  top: 0;
  left: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  font-size: 1rem;

  background-color: rosybrown;
}

.Toolbar nav {
  height: 100%;
  width: 100%;
}
a{
  text-decoration: none;
}
@media (max-width: 61.875em) {
  .DesktopOnly {
    display: none;
  }

  .Toolbar {
    background-color: #5c325f;
    height: 60px;
    width: 100%;
    visibility: visible;
  }
}
